// form selects
//const gfSelects = document.querySelectorAll('.gfield_select');
const gfSelects = document.querySelectorAll("select");

if (gfSelects) {

    gfSelects.forEach(select => {
        // $(select).customA11ySelect();
        jQuery(select).customA11ySelect();
    });

    const selectCnt = document.querySelectorAll(".custom-a11yselect-container");
    const selectButtons = document.querySelectorAll(".custom-a11yselect-btn");
    const shell = document.querySelector(".shell");

    // jQuery(document).on('gform_page_loaded', function(event, form_id, current_page){ can this be vanilla is the question?
    // document.addEventListener('gform_page_loaded', function(event, form_id, current_page){ // trying vanilla approach
    //     console.log('Not logging this');
    //     gfSelects.forEach(select => {
    //         $(select).customA11ySelect();
    //     });
    // });
}

// props submit focus
const propsBtn = document.querySelector(".props-form .gform_footer input[type='submit']");

function focusOn() {
    this.parentNode.classList.add("focus");
}

function focusOff() {
    this.parentNode.classList.remove("focus");
}

if (propsBtn) {
    propsBtn.addEventListener("focus", focusOn);
    propsBtn.addEventListener("blur", focusOff);    
}


// radio inputs
// const radioItems = document.querySelectorAll(".radio-item input");

// const radioEnter = () => {
//     this.parentNode.classList.add("hovering");
// }

// const radioLeave = () => {
//     this.parentNode.classList.remove("hovering");
// }

// radioItems.forEach(radio => {
//     radio.addEventListener("mouseenter", radioEnter);
//     radio.addEventListener("mouseleave", radioLeave);
// });


// file inputs
// const inputs = document.querySelectorAll("input[type='file']");

// if (inputs) {

//     function singleFile(file) {
//         const fileAdded = document.querySelector(".file-name");
//         fileAdded.innerHTML = "File Name: " + file.name + "<button class='file-remove'>Remove File</button>";
//         const fileRemove = document.querySelector(".file-remove");
//         fileRemove.addEventListener("click", removeFile);
//     }

//     function createFalseInput(input) {
//         input.insertAdjacentHTML("beforeBegin", "<button tabindex='0' class='button file-upload'>Choose File</button>");
//         input.parentNode.insertAdjacentHTML("afterEnd", "<div class='file-name'>No File Chosen</div>");
//         function addFile() {
//             const button = document.querySelector(".file-upload");
//             singleFile(this.files[0]);
//         }
//         input.addEventListener("change", addFile);
//     }

//     function removeFile() {
//         this.parentNode.previousSibling.children[2].value = null;
//         this.parentNode.innerHTML = "<div class='file-name'>No File Chosen</div>";
//     }

//     inputs.forEach(input => {
//         createFalseInput(input);
//     });
// }
/**
 * import external dependencies
 */
 import 'jquery';
 import Router from './util/Router';
 import 'van11y-accessible-modal-window-aria/dist/van11y-accessible-modal-window-aria.min.js'

/**
 * import internal dependencies
 */
import hamburger from './util/hamburger';
import select from './util/selectize';
import form from './util/form';
import stats from './util/stats';

/**
 * import local dependencies
 */
 import common from './routes/common'; // Loads on every page

 /**
  * Populate Router instance with DOM routes
  * @type {Router} routes - An instance of our router
  */
 const routes = new Router({
     hamburger,
     select,
     form,
     stats,
     common,
 });

/**
 * Polyfill Corrections useful for Vue
 */
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function(callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}
if (typeof NodeList.prototype.forEach !== 'function')  {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/**
 * Load Events
 */
$(() => routes.loadEvents());

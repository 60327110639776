//import _ from "lodash";

// hamburger
const menuCheck = (menuLinks) => {
	if (window.innerWidth < 1025) {
		menuLinks.forEach(link => {
			link.setAttribute("tabindex", "-1");
		});
	} else {
		menuLinks.forEach(link => {
			link.setAttribute("tabindex", "-1");
		});
	}
}

const trapFocus = (element, menuOpen, hamburgerButton) => {
	let focusableEls = element.querySelectorAll("a[href]:not([disabled]), button:not([disabled])");
	const firstFocusableEl = hamburgerButton;
	const lastFocusableEl = focusableEls[focusableEls.length - 1];
	const KEYCODE_TAB = 9;
  
	element.addEventListener("keydown", function(e) {
		if (menuOpen === true) {
			let isTabPressed = (e.key === "Tab" || e.keyCode === KEYCODE_TAB);
			if (!isTabPressed) return;

			/* shift + tab */
			if (e.shiftKey && document.activeElement === firstFocusableEl) {
				lastFocusableEl.focus();
				e.preventDefault();
			/* tab */
			} else if (document.activeElement === lastFocusableEl) {
				firstFocusableEl.focus();
				e.preventDefault();
			}
		}
	});
}

const hamburger = () => {
	let menuOpen = false;
	const html = document.querySelector("html");
	const body = document.querySelector("body");
	const hamburgerButton = document.querySelector(".hamburger");
	const hamburgerText = hamburgerButton.querySelector(".text");
	const menu = document.getElementById("menu");
	const logo = document.querySelector("header .logo");
	const mainCnt = document.querySelector("main");
	const footerCnt = document.querySelector("footer");
	let menuLinks = menu.querySelectorAll("a[href]:not([disabled]), button:not([disabled])");
	
	menuCheck(menuLinks);

	window.addEventListener("resize", () => menuCheck(menuLinks));

	hamburgerButton.addEventListener("click", () => {
		hamburgerButton.classList.toggle("is-active");
		menu.classList.toggle("open");
		body.classList.toggle("fixed");
		html.classList.toggle("fixed");

		if (hamburgerButton.classList.contains("is-active")) {
			menu.setAttribute("aria-expanded", "true");
			hamburgerText.innerHTML = "Close X";
			mainCnt.setAttribute("aria-hidden", "true");
			footerCnt.setAttribute("aria-hidden", "true");

			menuOpen = true;
			trapFocus(menu, menuOpen, hamburgerButton);
			menuLinks.forEach(link => {
				link.setAttribute("tabindex", "0");
			});
			logo.setAttribute("tabindex", "-1");

		} else {
			menu.setAttribute("aria-expanded", "false");
			hamburgerText.innerHTML = "Menu";
			mainCnt.setAttribute("aria-hidden", "false");
			footerCnt.setAttribute("aria-hidden", "false");
			menuOpen = false;
			menuLinks.forEach(link => {
				link.setAttribute("tabindex", "-1");
			});
			logo.setAttribute("tabindex", "0");
		}
	});
};

export default hamburger;

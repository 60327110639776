import { CountUp } from "countup.js/dist/countUp.min.js";

// stats
// const stats = document.querySelectorAll(".num");
// const pauseBtn = document.querySelector(".visual-pause");
// const pauseTabBtn = document.querySelector(".screen-reader-pause");
// let pauseStats = false;

const checkStat = () => {
    let statsNum;
    let pauseStats = false;
    const stats = document.querySelectorAll(".num");

    stats.forEach((element, index) => {
        let num = element.dataset.number;
        let numDecimal = element.dataset.decimal;
        let numPrefix = element.dataset.prefix;
        let numSuffix = element.dataset.suffix;
        element.setAttribute("id", "stat-"+index);
        const revealAt = (window.scrollY + window.innerHeight) - element.offsetHeight / 2;
        const statPosition = window.pageYOffset + element.getBoundingClientRect().top;
        const revealBottom = statPosition + element.offsetHeight;
        const isHalfShown = revealAt > statPosition;
        const isNotScrolledPast = window.scrollY < revealBottom;
        if (isHalfShown && isNotScrolledPast) {
            if (!element.classList.contains("on")){
                element.classList.add("on");
                const statsNumOptions = {
                    duration: 2,
                    decimalPlaces: numDecimal,
                    prefix: numPrefix,
                    suffix: numSuffix,
                    useEasing: false
                };
                if (pauseStats == false) {
                    statsNum = new CountUp("stat-"+ index +"", num, statsNumOptions);
                    statsNum.start();
                }
            }
        }
    });
}

window.addEventListener("scroll", checkStat);

// const pauseAnimations = () => {
//     pauseStats = true;
//     stats.forEach(stat => {
//         let num = stat.dataset.number;
//         let numPrefix = stat.dataset.prefix;
//         let numSuffix = stat.dataset.suffix;
//         stat.innerHTML = numPrefix + num + numSuffix;
//     });
// }

//pauseBtn.addEventListener("click", pauseAnimations);
//pauseTabBtn.addEventListener("click", pauseAnimations);

export default checkStat;
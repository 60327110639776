import hamburger from "../util/hamburger";
import Swiper, {Navigation, Pagination} from "swiper";
import checkStat from "../util/stats";
import { CountUp } from "countup.js/dist/countUp.min.js";

export default {
	init() {
	  	// JavaScript to be fired on all pages

	    /**
	     * Skip links
	     */

	    // skip link scroll to section
	    const skipToAnchor = (aid) => {
	        var aTag = $(aid);
	        var focus = true;
	        $('html,body').animate({
	            scrollTop: aTag.offset().top
	        }, 'slow');
	        var first_child = $(aTag.children()[0]);
	        var tag = first_child.prop('tagName').toLowerCase();

	        if (tag !== 'a' && tag !== 'button' && tag !== 'input' && tag !== 'textarea') {
	            if (first_child.attr('tabIndex') !== undefined) {
	                first_child.attr('tabIndex', -1).focus();
	                first_child.removeAttr('tabIndex');
	            } else {
	                first_child.focus();
	            }
	        } else {
	            first_child.focus();
	        }
	    }

	    // create skip links
	    const skipLinks = () => {
	        $('section').each(function () {
	            const id = $(this).attr('id');
	            if (id !== undefined) {
	                // Use the section id to create a label for the items in the skip link list
	                var sectionNameArray = id.split('-');
	                var sectionName = '';
	                for (var i = 0; i < sectionNameArray.length; i++) {
	                    var str = sectionNameArray[i];
	                    str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
	                        return letter.toUpperCase();
	                    });
	                    sectionName += str;
	                    if (i < sectionNameArray.length - 1) {
	                        sectionName += " ";
	                    }
	                }
	                var skiplink = "<li><a href='#" + id + "' class='text-link'>Skip to " + sectionName + "</a></li>";
	                $('.skiplinks ul').append(skiplink);
	            }
	        });

	        const skipLinkContainer = $('.skiplinks'),
	            skipLink = $('.skiplinks a');

	        skipLink.on('focus', function () {
	            skipLinkContainer.addClass('show');
	        });

	        skipLink.on('blur', function () {
	            skipLinkContainer.removeClass('show');
	        });

	        skipLink.on('click', function (e) {
	            e.preventDefault();
	            skipToAnchor($(this).attr('href'));
	        });
	    }

	    skipLinks();


	    /**
	     * Hamburger
	     */
		 hamburger();


	    /**
	     * Resize Timer
	     */
		let resizeTimer;
		window.addEventListener("resize", () => {
			document.body.classList.add("resize-animation-stopper");
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => {
				document.body.classList.remove("resize-animation-stopper");
			}, 400);
		});


	    /**
	     * Stats Carousel
	     */
		const checkSliderStat = () => {
			let statsNum;
			let pauseStats = false;
			const stats = document.querySelectorAll(".stats-section .swiper-slide .num");
		
			stats.forEach((element, index) => {
				let num = element.dataset.number;
				let numDecimal = element.dataset.decimal;
				let numPrefix = element.dataset.prefix;
				let numSuffix = element.dataset.suffix;
				element.setAttribute("id", "stat-"+index);
				element.classList.add("on");
				const statsNumOptions = {
					duration: 2,
					decimalPlaces: numDecimal,
					prefix: numPrefix,
					suffix: numSuffix,
					useEasing: false
				};
				if (pauseStats == false) {
					statsNum = new CountUp("stat-"+ index +"", num, statsNumOptions);
					statsNum.start();
				}
			});
		}

		if ($(".stats-section").length) {
			const slidesNum = document.querySelectorAll(".stats-section .swiper-slide").length;
			// const heroPagi = document.querySelector(".hero-swiper-pagination");
			const statsPrev = document.querySelector(".swiper-prev");
			const statsNext = document.querySelector(".swiper-next");
			let touch, doLoop;
			if (slidesNum < 2) {
				//heroPagi.remove();
				statsPrev.remove();
				statsNext.remove();
				touch = false;
				doLoop = false;
			} else {
				touch = true;
				doLoop = true;
			}


			// const statSliderFlip = () => {
			// 	const cpRow = document.querySelectorAll(".stats-section .swiper-slide");
			// 	let photo;
	
			// 	cpRow.forEach(element => {
			// 		let cnt = element.children;
			// 		let bodyChildren = cnt[1].children;
	
			// 		if (window.innerWidth < 1025) {
			// 			if (bodyChildren[0].classList.contains("photo")) {
			// 				bodyChildren[0].classList.add("moved");
			// 				photo = bodyChildren[0];
			// 				bodyChildren[0].remove();
			// 				element.insertAdjacentElement("afterbegin", photo);
			// 			}
			// 		} else {
			// 			// if (cnt[0].classList.contains("moved")) {
			// 			//     cnt[0].classList.remove("moved");
			// 			//     photo = cnt[0];
			// 			//     cnt[0].remove();
			// 			//     element.insertAdjacentElement("beforeend", photo);
			// 			// }
			// 		}
			// 	});
			// }
	
			// statSliderFlip();
	
			// window.addEventListener("resize", statSliderFlip);
	

			const statsCarousel = new Swiper(".stats-section .swiper-container", {
				loop: doLoop,
				simulateTouch: touch,
				autoHeight: true,
				navigation: {
					nextEl: '.swiper-next',
					prevEl: '.swiper-prev',
				},
				// pagination: {
				// 	el: ".hero-swiper-pagination",
				// 	clickable: true,
				// },
				a11y: {
					prevSlideMessage: "Previous slide",
					nextSlideMessage: "Next slide",
					firstSlideMessage: "This is the first slide",
					lastSlideMessage: "This is the last slide",
					paginationBulletMessage: "Go to slide {{index}}",
				},
				on: {
					init: function () {
						checkStat();

						// $(".swiper-slide .btn").attr("tabindex", "-1");
						// $(".swiper-slide-active .btn").attr("tabindex", "0");
					},
					transitionStart: function () {
						// $(".swiper-slide .btn").attr("tabindex", "-1");
						// $(".swiper-slide-active .btn").attr("tabindex", "0");
					},
					transitionEnd: function () {
						
					},
				},
			});

			const swiperButtons = document.querySelectorAll(".stats-section .swiper-controls button");

			swiperButtons.forEach(button => {
				button.addEventListener("click", checkSliderStat);
			});
		}

		
		/**	
	     * Story Flex
	     */
		const storyItems = document.querySelectorAll(".story-item");
		const expandBtn = document.querySelectorAll(".story-item .person .expand");
		const expandMobileBtn = document.querySelectorAll(".story-item .person .expand-mobile");

		function heightSet() {
			if (window.innerWidth > 1023) {
				let maxHeight = 0;
				let maxWidth = 0;
				storyItems.forEach(item => {
					item.removeAttribute("style");
				});
				storyItems.forEach(item => {
					let thisH = item.clientHeight;
					maxWidth = item.clientWidth;
					if (thisH > maxHeight) { maxHeight = thisH; }
				});
				storyItems.forEach(item => {
					item.style.height = maxHeight+"px";
					item.firstElementChild.firstElementChild.style.width = maxWidth+"px";
				});
			}
		}
		heightSet();

		window.addEventListener("resize", heightSet);

		function toggleStory() {
			if (window.innerWidth > 1023) {
				const storyCnt = this.parentNode.parentNode.parentNode.parentNode;
				const storyEl = storyCnt.children;

				if (this.parentNode.parentNode.parentNode.classList.contains("open")) {
					for (let item of storyEl) {
						item.classList.remove("collapse");
					}
					this.parentNode.parentNode.parentNode.classList.remove("open");
					this.innerHTML = "Expand";
					this.parentNode.nextElementSibling.setAttribute("aria-expanded", "false");
				} else {
					for (let item of storyEl) {
						item.classList.remove("open");
						item.classList.add("collapse");
					}
					this.parentNode.parentNode.parentNode.classList.remove("collapse");
					this.parentNode.parentNode.parentNode.classList.add("open");
					this.innerHTML = "Collapse";
					this.parentNode.nextElementSibling.setAttribute("aria-expanded", "true");
				}
			}
		}

		function toggleStoryMobile() {
			if (window.innerWidth < 1024) {
				const storyCnt = this.parentNode.parentNode;
				const storyEl = storyCnt.children;

				if (this.parentNode.parentNode.classList.contains("open")) {
					this.parentNode.parentNode.classList.remove("open");
					this.innerHTML = "Expand";
					this.previousElementSibling.setAttribute("aria-expanded", "false");
				} else {
					for (let item of storyEl) {
						item.classList.remove("open");
					}
					this.parentNode.parentNode.classList.add("open");
					this.innerHTML = "Collapse";
					this.previousElementSibling.setAttribute("aria-expanded", "true");
				}
			}
		}

		expandBtn.forEach(expand => {
			expand.addEventListener("click", toggleStory);
		});

		expandMobileBtn.forEach(expandMobile => {
			expandMobile.addEventListener("click", toggleStoryMobile);
		});


	    /**
	     * Responsive
	     */
		const cpFlip = () => {
            const cpRow = document.querySelectorAll(".pc-section .split-grid");
            let photo;

            cpRow.forEach(element => {
                let cnt = element.children;
                if (window.innerWidth < 1025) {
                    if (cnt[1].classList.contains("photo")) {
                        cnt[1].classList.add("moved");
                        photo = cnt[1];
                        cnt[1].remove();
                        element.insertAdjacentElement("afterbegin", photo);
                    }
                } else {
                    if (cnt[0].classList.contains("moved")) {
                        cnt[0].classList.remove("moved");
                        photo = cnt[0];
                        cnt[0].remove();
                        element.insertAdjacentElement("beforeend", photo);
                    }
                }
            });
        }

        cpFlip();

        window.addEventListener("resize", cpFlip);


		const cpAboutFlip = () => {
            const cpRow = document.querySelectorAll(".about-section .split-grid");
            let photo;

            cpRow.forEach(element => {
                let cnt = element.children;
                if (window.innerWidth < 1025) {
                    if (cnt[1].classList.contains("photo")) {
                        cnt[1].classList.add("moved");
                        photo = cnt[1];
                        cnt[1].remove();
                        element.insertAdjacentElement("afterbegin", photo);
                    }
                } else {
                    if (cnt[0].classList.contains("moved")) {
                        cnt[0].classList.remove("moved");
                        photo = cnt[0];
                        cnt[0].remove();
                        element.insertAdjacentElement("beforeend", photo);
                    }
                }
            });
        }

        cpAboutFlip();

        window.addEventListener("resize", cpAboutFlip);


		const cpDonateFlip = () => {
            const cpRow = document.querySelectorAll(".donation-section .split-grid");
            let photo;

            cpRow.forEach(element => {
                let cnt = element.children;
                if (window.innerWidth < 1025) {
                    if (cnt[1].classList.contains("photo")) {
                        cnt[1].classList.add("moved");
                        photo = cnt[1];
                        cnt[1].remove();
                        element.insertAdjacentElement("afterbegin", photo);
                    }
                } else {
                    if (cnt[0].classList.contains("moved")) {
                        cnt[0].classList.remove("moved");
                        photo = cnt[0];
                        cnt[0].remove();
                        element.insertAdjacentElement("beforeend", photo);
                    }
                }
            });
        }

        cpDonateFlip();

        window.addEventListener("resize", cpDonateFlip);

	},
	finalize() {
	    // JavaScript to be fired on all pages, after page specific JS is fired

		// preload
        const preload = document.querySelector("body");
        preload.classList.remove("preload");

		// set responsive menu
		const menu = document.getElementById('menu');

		const setMenu = () => {
			if (window.innerWidth < 1025){
				let browserHeight = window.innerHeight;
				menu.style.height = browserHeight+'px';
			} else {
				menu.style = '';
			}
		}
		setMenu();

		window.addEventListener('resize', () => setMenu());

	}
};
